// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-not-found-template-js": () => import("./../src/templates/not-found-template.js" /* webpackChunkName: "component---src-templates-not-found-template-js" */),
  "component---src-templates-tags-list-template-js": () => import("./../src/templates/tags-list-template.js" /* webpackChunkName: "component---src-templates-tags-list-template-js" */),
  "component---src-templates-categories-list-template-js": () => import("./../src/templates/categories-list-template.js" /* webpackChunkName: "component---src-templates-categories-list-template-js" */),
  "component---src-templates-page-template-js": () => import("./../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-post-template-js": () => import("./../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-tag-template-js": () => import("./../src/templates/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-js" */),
  "component---src-templates-category-template-js": () => import("./../src/templates/category-template.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-index-template-js": () => import("./../src/templates/index-template.js" /* webpackChunkName: "component---src-templates-index-template-js" */),
  "component---src-pages-pages-portfolio-js": () => import("./../src/pages/pages/portfolio.js" /* webpackChunkName: "component---src-pages-pages-portfolio-js" */),
  "component---src-pages-pages-portfolio-jtrt-js": () => import("./../src/pages/pages/portfolio/jtrt.js" /* webpackChunkName: "component---src-pages-pages-portfolio-jtrt-js" */),
  "component---src-pages-pages-portfolio-kwrc-js": () => import("./../src/pages/pages/portfolio/kwrc.js" /* webpackChunkName: "component---src-pages-pages-portfolio-kwrc-js" */),
  "component---src-pages-pages-portfolio-lazygo-js": () => import("./../src/pages/pages/portfolio/lazygo.js" /* webpackChunkName: "component---src-pages-pages-portfolio-lazygo-js" */),
  "component---src-pages-pages-portfolio-master-raider-js": () => import("./../src/pages/pages/portfolio/masterRaider.js" /* webpackChunkName: "component---src-pages-pages-portfolio-master-raider-js" */),
  "component---src-pages-pages-privacy-policy-js": () => import("./../src/pages/pages/privacyPolicy.js" /* webpackChunkName: "component---src-pages-pages-privacy-policy-js" */),
  "component---src-pages-pages-terms-of-service-js": () => import("./../src/pages/pages/termsOfService.js" /* webpackChunkName: "component---src-pages-pages-terms-of-service-js" */),
  "component---src-pages-pages-wptools-js": () => import("./../src/pages/pages/wptools.js" /* webpackChunkName: "component---src-pages-pages-wptools-js" */)
}

